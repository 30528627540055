import React from "react"
import loadable from "@loadable/component"

const Layout = loadable(() => import("@common/Layout/Layout"))

const ArtificialIntelligence = loadable(() => import("@components/ArtificialIntelligence"));

const SEO = loadable(() => import("@components/Common/SEO/SEO"))

const ArtificialIntelligencePage = ({ path }) => {
  return (
    <>
      <SEO
        title="AI and ML development services for innovative companies"
        titleTemplate="Impressit"
        description="Boost your business with AI/ML solutions that are custom-built for innovators. Stay ahead of the curve with cutting-edge technology."
        path={path}
      />
      <Layout path={path} isNoPadding>
        <ArtificialIntelligence />
      </Layout>
    </>
  )
}

export default ArtificialIntelligencePage;
